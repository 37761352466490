import React from "react";
import * as style from "./style.module.scss";
import Logo from "../Logo";
import { StaticImage } from "gatsby-plugin-image";

const VisualLandingConcorsoMonza = () => {
    const imagePath = "../../images/";

    return (
        <div className={style.visual}>
            <div className={style.visualInfos}>
                <div className={style.wrapperLogo}>
                    <a href={`${process.env.GATSBY_SITE_URL}/it`}>
                        <Logo height={"30px"}/>
                    </a>
                </div>

                <h1>Con PIRELLI Care<sup>TM</sup> vivi la gara F1® da protagonista</h1>
                <p>Vinci <span className={style.redSpan}> 2 biglietti di ingresso</span> alla tribuna del <strong className={style.lineBreak}>FORMULA 1® PIRELLI GRAN PREMIO D'ITALIA 2022,</strong> al circuito di Monza e un <strong>super premio</strong>: <span className={style.redSpan}>uno slot</span> <strong className={style.lineBreak}>Formula 1 Pirelli Hot Laps™</strong> per girare in pista insieme ai migliori piloti professionisti durante la domenica di gara</p>
                <a className={style.linkRegolamento} href="https://assets.pirellicare.com/docs/legal/PirelliCare_Formula1_IT_it.pdf" target="_blank">Leggi qui il regolamento</a>
            </div>

            <div className={style.visualImage}>
                <StaticImage src={`${imagePath}visual-concorso-monza.png`} alt="visual concorso monza"/>
            </div>
        </div>
    )
}

export default VisualLandingConcorsoMonza;