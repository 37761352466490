import React from "react";
import * as style from "./style.module.scss";

const ConcorsoMonzaEstrazioni = () => {
    return (
        <div className={style.wrapperEstrazioni}>
            <p className={style.title}>Acquista, dal <strong>28 Luglio</strong> fino al <strong>21 Agosto</strong>, un pacchetto <strong>Smart</strong> o <strong>Superior</strong> <span>di PIRELLI Care™, inizi a pagare solo dopo il montaggio dei pneumatici</span> <span>e partecipi all’estrazione di:</span></p>

            <div className={style.wrapperPromos}>
                <div className={style.promoBox}>
                    <p className={style.promoTitle}>Primo premio</p>

                    <ul>
                        <li><strong>1 slot Formula 1 Pirelli Hot Laps™</strong>: un giro in pista con i migliori piloti professionisti (domenica 11 settembre)</li>
                        <li><strong>2 biglietti</strong> di ingresso alla tribuna, variante Ascari, per vivere il Gran Premio (domenica 11 settembre)</li>
                    </ul>
                </div>

                <div className={style.promoBox}>
                    <p className={style.promoTitle}>Dal secondo al quinto estratto*</p>

                    <ul>
                        <li><strong>2 biglietti</strong> di ingresso alla tribuna, variante Ascari, per vivere il Gran Premio (domenica 11 settembre)</li>
                    </ul>

                    <p className={style.promoBoxNote}>*In palio 4 coppie di biglietti di ingresso alla tribuna (2 biglietti per estratto)</p>
                </div>
            </div>
        </div>
    )
}

export default ConcorsoMonzaEstrazioni;