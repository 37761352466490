import * as React from "react";
import VisualLandingConcorsoMonza from '../VisualLandingConcorsoMonza';

function ContentLandingConcorsoMonza({datas, countryCode}) {
    return (
        <>
            <VisualLandingConcorsoMonza datas={datas} />
        </>
    )
}

export default ContentLandingConcorsoMonza;