import React, {useEffect, useRef, useState} from 'react';
import ItJson from '../translations/it.json';
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Faq from "../../components/FAQ";
import StickyElement from "../../components/StickyElement";
import MblwhatsappBtn from "../../components/MblwhatsappBtn";
import ContentLandingConcorsoMonza from "../../components/ContentLandingConcorsoMonza";
import ConcorsoMonzaEstrazioni from "../../components/ConcorsoMonzaEstrazioni";
import ServicesConcorsoMonza from "../../components/ServicesConcorsoMonza";

const ConcorsoMonza = () => {
    const [tabletDown, setTabletDown] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        setTabletDown(window.innerWidth < 1024);
        setIsDesktop(window.innerWidth >= 1200);

        window.addEventListener("resize", () => {
            setTimeout(() => {
                setTabletDown(window.innerWidth < 1024);
                setIsDesktop(window.innerWidth >= 1200);
            }, 1);
        });
    }, []);

    const refDelearLocator = useRef();
    const refFaq = useRef();
    const handleScroll = (ref) => {
        let goto = null;
        if (ref === "dealerlocator") {
            goto = refDelearLocator.current;
        } else if (ref === "faq") {
            goto = refFaq.current;
        }

        if (goto !== null) {
            window.scrollTo({
                top: goto.offsetTop,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    const hideComponent = true;
    const concorsoMonza = true;

    return (
        <>
            <Navbar
                countryCode={ItJson.countryCode}
                supports={ItJson.supports}
                handleScroll={handleScroll}
                hideComponent={hideComponent}
            />

            <ContentLandingConcorsoMonza
                datas={ItJson}
                countryCode={ItJson.countryCode}
            />

            <ConcorsoMonzaEstrazioni
                datas={ItJson}
                countryCode={ItJson.countryCode}
            />

            <ServicesConcorsoMonza
                datas={ItJson}
                countryCode={ItJson.countryCode}
            />

            <div ref={refFaq}>
                <Faq
                    countryCode={ItJson.countryCode}
                    faqList={ItJson.faq}
                    labelScrollTo={ItJson.labelscroolTo}
                    selectedDealers={ItJson.selectedDealers}
                    faqTitleSection={ItJson.faqTitleSection}
                    otherQuestions={ItJson.otherQuestions}
                    handleScroll={handleScroll}
                    hideComponent={hideComponent}
                />
            </div>

            <Footer footerDatas={ItJson.footer} />

            <StickyElement
                tabletDown={tabletDown}
                isDesktop={isDesktop}
                stickyElementData={ItJson.stickySection}
                countryCode={ItJson.countryCode}
                concorsoMonza={concorsoMonza}
            />

            <MblwhatsappBtn />
        </>
    )
}

export default ConcorsoMonza;