import React from "react";
import * as style from "./style.module.scss";

const ServicesConcorsoMonza = () => {
  return (
    <div className={style.sectionServices}>
      <p className={style.sectionTitle}>
        Due livelli di{" "}
        <span>
          <strong>servizio per te</strong>
        </span>
      </p>

      <div className={style.wrapperServices}>
        <div className={style.seviceBox}>
          <p className={style.boxTitle}>Superior</p>

          <p className={style.boxDescription}>
            <strong>Pneumatici e servizi ad alto valore</strong> per la cura
            della tua auto ogni volta che ti serve.
          </p>

          <div className={style.wrapperList}>
            <ul>
              <li>Set di pneumatici Pirelli</li>
              <li>Montaggio pneumatici</li>
              <li>Reset sensori TPMS</li>
              <li>Equilibratura</li>
              <li>Check-up del veicolo</li>
              <li>Assetto completo</li>
              <li>Sanificazione del veicolo</li>
              <li>Estensione di garanzia</li>
            </ul>

            <ul>
              <li className={style.plusIcon}>Pacchetto Easy</li>
              <li className={style.plusIcon}>Deposito stagionale</li>
            </ul>
          </div>

          <a
            href={`${process.env.GATSBY_ONBOARDING_PORTAL_LINK}/it/?bundle=9379700`}
            target="_blank"
            onClick={(e) => {
              window.dataLayer.push({
                event: "GAevent",
                eventName: "call_to_action",
                cta_name: `start-funnel-superior`,
              });
            }}
          />
        </div>

        <div className={style.seviceBox}>
          <p className={style.boxTitle}>Smart</p>

          <p className={style.boxDescription}>
            Il pacchetto che ti toglie il pensiero del{" "}
            <strong>cambio gomme</strong>.
          </p>

          <div className={style.wrapperList}>
            <ul className={style.smart}>
              <li>Set di pneumatici Pirelli</li>
              <li>Montaggio pneumatici</li>
              <li>Reset sensori TPMS</li>
              <li>Equilibratura</li>
              <li>Estensione di garanzia</li>
            </ul>

            <ul>
              <li className={style.plusIcon}>Pacchetto Easy</li>
              <li className={style.plusIcon}>Deposito stagionale</li>
            </ul>
          </div>

          <a
            href={`${process.env.GATSBY_ONBOARDING_PORTAL_LINK}/it/?bundle=9379400`}
            target="_blank"
            onClick={(e) => {
              window.dataLayer.push({
                event: "GAevent",
                eventName: "call_to_action",
                cta_name: `start-funnel-smart`,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ServicesConcorsoMonza;
